import axios from 'axios'
import {mgwEnvironmentApi} from "../helpers";

export const axiosInstance = axios.create({
  baseURL: `https://${mgwEnvironmentApi}`,
  headers: {
    'X-Device-Model': 'PC_FairPlay_v1',
    'X-Device-Id': 1,
    'X-App-Version': 1,
  },
})
