import { FC, useCallback } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Heading, Ratings, Button, Paragraph } from '@components'
import { ContentsApi, MetaResponseApi, MetaResponseApiTypeEnum, SeasonApi, EpisodeApi } from 'mgw-api'
import { gtm } from 'helpers/GTMService'
import { mediaQueries } from 'styles/global-styles'
import {mgwEnvironmentApi} from "../../../../../../../helpers";

const contentsApi = new ContentsApi(undefined, `https://${mgwEnvironmentApi}`)

interface IDetails {
  onAddToFavourites: () => void
  selected: MetaResponseApi
}

export const Details: FC<IDetails> = observer(({ onAddToFavourites, selected }) => {
	async function onOpen() {
		gtm.pushEvent({
		  event: 'mayak_button_click', 
		  event_name: 'mayak_button_click', 
		  button_id: '3',
		  button_text: 'Смотреть в KION',
		})
	
		const win = window.open('', '_blank');
	
		const url = ['//kion.ru', 'video']
		if (!selected.hid) return
	
		const fetchData = async () => {
			if (selected.hid) {
				if (selected.type === MetaResponseApiTypeEnum.Series) {
					const {
						data: { seasons },
					} = await contentsApi.getSeasonsBySeriesGid(selected.hid, '0', 'PC_FairPlay_v1', '0', '0')
					const [{ hid: seasonContentId, episodes }] = seasons as SeasonApi[]
					const [{ hid: episodeContentId }] = episodes as EpisodeApi[]
				
					url.push(...['serial', selected.hid, 'season', seasonContentId || '', 'episode', episodeContentId || ''])
				} else {
					url.push(...['movie', selected.hid])
				}
		
				if (win) {
					win.location.href = url.join('/');
				}
			}
		};
	
		fetchData();
		// window.open(url.join('/'), '_blank')
	  }

  return (
    <Container>
      <FilmName as='h2' family='MTS Sans' align="center">
        {selected.title}
      </FilmName>

      <Info>
        {selected.releaseYear}
        {/* <Devider/>
        2 сезона */}
        <Devider/>
        {selected.countries?.map((x, idx) => (idx > 0 ? ', ' : '') + x.name)}
        <Devider/>
        {selected.ageRestriction}+
      </Info>

      {selected.ratings && (
        <Ratings mts={selected.ratings.mts} imdb={selected.ratings.imdb} kinopoisk={selected.ratings.kinopoisk} />
      )}
      
      <Button className='primary' onClick={onOpen} width={'100%'}>
        Смотреть в KION
      </Button>

      <Description>
        <Paragraph family="MTS Text" size="14px" lineHeight="20px">{selected.description}</Paragraph>
      </Description>
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;
  max-width: 500px;
  width: 100%;
  justify-self: center;

  @media screen and (max-width: 768px) {
    padding: 0 16px 32px 16px;
  }
`

const FilmName = styled(Heading)`
  font-weight: 700;
  font-size: 24px;

  ${mediaQueries.mobile} {
    font-size: 17px;
  }
`

const Info = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const Devider = styled.div`
  width: 1px;
  background-color: white;
  height: 90%;
  flex-shrink: 0;
`

const Description = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1.125rem;
`
