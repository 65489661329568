import { makeAutoObservable } from 'mobx'
import { ShelfItemApi } from 'mgw-api'
import {debounce, mgwEnvironmentApi} from 'helpers'
import { gtm } from 'helpers/GTMService'
import axios from 'axios'

const searchApi = axios.create({
  baseURL: `https://${mgwEnvironmentApi}/mgw-hostess/api/explore/search`,
  headers: {
    'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
    'X-Device-Id': 'explore_kion_ru',
    'X-App-Version': '1'
  },
})

const popularApi = axios.create({
  baseURL: `https://${mgwEnvironmentApi}/mgw-hostess/api/explore/popular`,
  headers: {
    'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
    'X-Device-Id': 'explore_kion_ru',
    'X-App-Version': '1'
  },  
})

export class SearchStore {
  public isSearchOpen: boolean = false
  public query: string = ''
  public searchResult: ShelfItemApi[] = []
  public popular: ShelfItemApi[] = []
  public loading: boolean = false
  public pickedItems: ShelfItemApi[] = []

  constructor() {
    makeAutoObservable(this)
  }

  public openSearch = (): void => {
    this.isSearchOpen = true
  }

  public closeSearch = (): void => {
    this.isSearchOpen = false;
    setTimeout(() => {
      this.query = '';
    }, 300)
  }

  public setQuery = (str: string): void => {
    this.query = str;
  }

  public clearQuery = (): void => {
    this.query = '';
  }

  public setPickedItems = (newVal: ShelfItemApi[]): void => {
    this.pickedItems = newVal;
  }

  public pickItem = (item: ShelfItemApi): void => {
    this.pickedItems = [...this.pickedItems, item];
  }

  public removeItem = (gid: string): void => {
    this.pickedItems = this.pickedItems.filter((item) => item.gid !== gid);
  }

  public fetchSearch = (term: string): void => {
    this.loading = true;

    const searchDebounced = debounce(async () => {      
      const res = await searchApi.get('', {
        params: {
          term,
          limit: 30,
        },
      })

      // Удалить фильмы, у которых нет постеров
      const validItems = res.data.items.filter((x: any) => !!x.posters?.pictureList?.length)
      this.searchResult = validItems.map((x: any) => {
        x.imageUrl = x.posters?.pictureList?.[0]?.url
        return x;
      })
      this.loading = false
    }, 350)

    searchDebounced()
  }

  public fetchPopular = (): void => {
    this.loading = true;

    const fetchPopularDebounced = debounce(async () => {      
      const res = await popularApi.get('', { params: { limit: 30 } })

      this.popular = res.data.items.filter((x: ShelfItemApi) => !x.imageUrl?.startsWith('{bu}')) || []
      this.loading = false
    }, 350)

    fetchPopularDebounced()
  }
}